/** Wrap root element with Redux Provider */
import React from 'react'
import { Provider } from 'react-redux'
import { defaultTheme } from '@signatu/styles'
import { ThemeProvider, createTheme, StylesProvider, createGenerateClassName } from '@material-ui/core'

import { store } from '@signatu/app'

const generateClassName = createGenerateClassName({
    productionPrefix: 's',
})

const Providers = ({ element }) => (
    <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <Provider store={store}>{element}</Provider>
        </ThemeProvider>
    </StylesProvider>
)
export default Providers
